import {
    compose, withPropsOnChange, withState, 
} from 'recompose'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import L from 'leaflet'
import { Marker } from 'react-leaflet'
import { addNotification as notify } from 'reapop'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { getEntities } from '@infotech/lib-api-middleware'
import { withToken } from '@infotech/auth'
import { createNotifyConf } from '@infotech/notify'
import {
    MapComponent,
} from '@infotech/base-components'

import './map.scss'
import { SG_REFERENCE_HOST } from 'api/constants'
import { objectsFetchApiAction, defectsFetchApiAction, historyFetchApiAction, } from './transport'
import { newDefects, selectObject, STREETPART_SELECTED_OBJECT, STREETPART_DEFECTS } from '../../reducer'
import { ifDayEntity } from '../../fieldsConf'

const TERMINAL_STATUSES = [`COMPLETE`, `ARCHIVE`]

const enhancer = compose(
    withPropsOnChange(['data'], ({ data: { id } }) => ({ key: id })),   
    withRouter, 
    withPropsOnChange(['location'], ({ location: { pathname } }) => ({ shiftId: ifDayEntity(pathname) ? 2 : 1 })),    
    withState(`objects`, `setObjects`),
    connect((state, { data: { id } }) => ({
        selectedObject: state[STREETPART_SELECTED_OBJECT][id],
        defects: state[STREETPART_DEFECTS][id],
        loComponentDict: getEntities(state, `lo_component`),
        defectTypeDict: getEntities(state, `defect_type`),
        loClassDict: getEntities(state, `lo_class`),
    })),
    connect(null, (dispatch, { 
        defects,
        setObjects,
        objects,
        data: { id, inventoryNumber },
        loComponentDict,
        defectTypeDict,
        shiftId,
    }) => { 
        const setObjectsWithDefects = (objects, defects) => {
            const os = [...objects] 
            defects.forEach(d => {
                const i = os.findIndex(o => o.$id$ === d.$lo_id$)
                if (i !== -1) {
                    const lo_component = loComponentDict[d.lo_component?.link?.key]?.short_name
                    const defect_type = defectTypeDict[d.defect_type?.link?.key]?.short_code
                    const codes = os[i].codes || [] 
                    const noLightQuantity = os[i].noLightQuantity || 0
                    os[i] = { 
                        ...os[i], 
                        hasDefects: true,
                        hasNoLight: d.defect_type?.link?.key.includes(`NO_LIGHT`) || os[i].hasNoLight,
                        noLightQuantity: d.defect_type?.link?.key.includes(`NO_LIGHT`) ? (noLightQuantity + d.lamps) : noLightQuantity,
                        continuous: d.continuous || os[i].continuous, // update only if true
                        sequential: d.sequential || os[i].sequential,
                        codes: defect_type ? [...codes, `${lo_component || ``}.${defect_type}`] : codes,
                        repeatedDefect: d.checkup_count ? true : os[i].repeatedDefect,
                        elimination: (d.elimination_number !== null) ? true : os[i].elimination,
                        eliminationRejected: (d.elimination_reject_date !== null) ? true : os[i].eliminationRejected,
                        twentyFour: d.twenty_four || os[i].twentyFour,
                    }
                }                    
            })
            setObjects(os)
        }

        return {
            selectObject: (object) => {
                dispatch(selectObject(id, object))
            },
            fetchHistory: () => {                
                dispatch(selectObject(id, null)) // erase selected object on page load
                dispatch(historyFetchApiAction(id, shiftId)).then((response)=>{
                    if(response.error) {
                        dispatch(notify(createNotifyConf(`Ошибка загрузки данных`, `error`)))
                        setObjects([])
                        dispatch(newDefects(id, []))
                        return
                    }
                    const objects = response.response.json.lightObjects || []
                    const defects = response.response.json.defects || []
                    dispatch(newDefects(id, defects))
                    setObjectsWithDefects(objects, defects)
                })
            },
            fetchObjects: () => {
                dispatch(selectObject(id, null)) // erase selected object on page load
                dispatch(newDefects(id, undefined))
                dispatch(objectsFetchApiAction(inventoryNumber)).then((response)=>{
                    if(response.error) {
                        dispatch(notify(createNotifyConf(`Ошибка загрузки объектов`, `error`)))
                        return
                    }
                    setObjects(response.response.json)
                })
            },
            fetchDefects: () => {
                if(objects === undefined) {
                    return
                }
                if(!objects.length) {
                    dispatch(newDefects(id, []))
                    return
                }
                if(defects !== undefined) {
                    return
                }
                const objectsIds = objects.map(o=>o.$id$)            
                dispatch(defectsFetchApiAction(objectsIds, shiftId)).then((response)=>{
                    if(response.error) {
                        dispatch(notify(createNotifyConf(`Ошибка загрузки дефектов`, `error`)))
                        return
                    }
                    const defects = response.response.json
                    dispatch(newDefects(id, defects))
                    setObjectsWithDefects(objects, defects)                    
                })
            },
        }
    }),
    withPropsOnChange([], ({ data: { status }, fetchObjects, fetchHistory }) => {
        if (TERMINAL_STATUSES.includes(status)) {
            fetchHistory()
        } else {
            fetchObjects()
        }
    }),
    withPropsOnChange(['objects'], ({ data: { status }, fetchDefects }) => {
        if (!TERMINAL_STATUSES.includes(status)) {
            fetchDefects()
        }
    }),
    withToken,
)

const Map = ({ objects, selectObject, selectedObject, loClassDict, shiftId, token }) => {
    if (isEmpty(objects)) {
        return null
    }

    const container = document.querySelector(`.leaflet-container`)
    if (container) {
        container.focus = function(){} // to prevent page scrolling on clicking the map
    }

    const divIconGenerator = ({ 
        defective, noLight, selected, reconstruction, 
        continuous, sequential, codes, icon,
        number, lamps, noLightQuantity, repeatedDefect,
        elimination, eliminationRejected, twentyFour, redOutline,
    }) => {
        const mask = `url('${SG_REFERENCE_HOST}/api/filestorage/images/file/${icon}/content?${queryString.stringify({
            token, inline: true
        })}')`

        let cn = `marker-cont`
        if (defective) {
            cn += ` orange`
        }
        if (noLight) {
            cn += ` red`
        }
        if (selected) {
            cn += ` selected-marker`
        }
        if (redOutline) {
            cn += ` red-outline`
        }

        return new L.DivIcon({
            html: ReactDOMServer.renderToString(
                <div className={cn}>
                    {selected && <div className="above-label">{number}</div>}
                    <div className="below-label">                        
                        {noLightQuantity ? `${noLightQuantity} / ` : ``}
                        {lamps}
                    </div>
                    {codes && <div className="below-label">
                        {codes.join(`, `)}
                    </div>}
                    <div 
                        className="icon icon-lamp"
                        style={icon ? {                        
                            mask,
                            "WebkitMask": mask,
                            "maskSize": "16px",
                            "WebkitMaskSize": "16px",
                        } : {}}
                    />
                    {reconstruction && <div className='info-icons info-icons-reconstruction'>
                        <div className='icon-reconstruction' />
                    </div>}
                    {
                        (sequential && <div className='info-icons'>
                            <div className='icon-chain' />
                        </div>)
                        || (continuous && <div className='info-icons'>
                            <div className='icon-clock' />
                        </div>)
                    }
                    {twentyFour && <div className='info-icons info-icons-twenty-four'>
                        <div className='icon-twenty-four' />
                    </div>}
                    {repeatedDefect && <div className='info-icons info-icons-repeated-defect'>
                        <div className='icon-repeated-defect' />
                    </div>}
                    {(elimination || eliminationRejected) && <div className='info-icons info-icons-elimination'>
                        <div className="icon-elimination" />
                        {eliminationRejected && <div className="icon-elimination-rejected" />}
                    </div>}
                </div>
            ),
            iconSize  : [28, 28],
            iconAnchor: [14, 14],  
        })
    }

    return <div className="streetpart-map">
        <MapComponent
            zoom={16}
            center={[...(selectedObject || objects[0]).point.coordinates].reverse()}
        >            
            {objects.map(o => <Marker 
                key={o.$id$} 
                position={[...o.point.coordinates].reverse()}
                onClick={() => {
                    selectObject(o)
                    const row = document.querySelector(`.streetmap-defects-row[data-object="${o.$id$}"]`)
                    row && row.scrollIntoView({ block: `center`, behavior: `smooth` })
                }}
                zIndexOffset={(selectedObject?.$id$ === o.$id$) ? 2000 : (o.hasDefects ? 1000 : 0)}
                icon={divIconGenerator({
                    icon: loClassDict[o.lo_class?.link?.key]?.icon?.id,
                    defective: o.hasDefects,
                    noLight: o.hasNoLight,
                    selected: selectedObject?.$id$ === o.$id$,
                    reconstruction: o.reconstruction,
                    sequential: (shiftId === 1) && o.sequential,
                    continuous: (shiftId === 1) && o.continuous,
                    codes: (shiftId === 2) ? (o.codes || []) : undefined,
                    number: o.code,
                    lamps: ((shiftId === 1) && (o.lo_class?.link?.key === `LAMP`)) ? o.lamps : undefined,
                    noLightQuantity: ((shiftId === 1) && (o.lo_class?.link?.key === `LAMP`)) ? o.noLightQuantity : undefined,
                    repeatedDefect: o.repeatedDefect,
                    elimination: o.elimination,
                    eliminationRejected: o.eliminationRejected,
                    twentyFour: (shiftId === 2) && o.twentyFour,
                    redOutline: (shiftId === 2) && o.twentyFour && o.eliminationRejected,
                })}
            />)}
        </MapComponent>
    </div>
}

export default enhancer(Map)